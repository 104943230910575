import { Meta, Tag } from './Internal';

export class TagGroup extends Meta {
  static entity = 'tag-groups';

  static fields () {
    return {
      ...super.fields(),
      label: this.string(null),
      tags: this.hasMany(Tag, 'tag_group_uuid'),
    }
  }
}
