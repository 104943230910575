import { Model } from 'pinia-orm';
import { ViewField, MetaRepository } from './Internal';
import { PSViewFieldType } from '@/api/Api';
import { DateCast, BooleanCast } from 'pinia-orm/casts';

export class DataModel extends Model {
  static castsCache: Object|null = null;

  static fields () {
    const viewFields = useRepo(ViewField).where('repository_uuid', this.name).get();
    const result = viewFields.reduce(
      (carry, field) => {
        switch (field.type) {
          case PSViewFieldType.HasMany:
            carry = {...carry, [field.uuid]: this.hasMany(useRepo(MetaRepository).find(field.foreignRepositoryUuid).dataModel, field.relationFieldUuid)};
            break;
          case PSViewFieldType.HasOne:
            carry = {...carry, [field.uuid]: this.hasOne(useRepo(MetaRepository).find(field.foreignRepositoryUuid).dataModel, field.relationFieldUuid)};
            break;
          case PSViewFieldType.BelongsTo:
            carry = {...carry, [field.relationFieldUuid]: this.attr(null)};
            carry = {...carry, [field.uuid]: this.belongsTo(useRepo(MetaRepository).find(field.foreignRepositoryUuid).dataModel, field.relationFieldUuid)};
            break;
          default:
            carry = {...carry, [field.propertyPath]: this.attr(null)};
            break;
        }

        return carry;
      },
      {},
    );

    return {
      id: this.attr(null),
      ...result
    }
  }

  static casts() {
    if (!this.castsCache) {
      const viewFields = useRepo(ViewField).where('repository_uuid', this.name).get();
      const result = viewFields.reduce(
        (carry, field) => {
          switch (field.type) {
            case PSViewFieldType.DateTime:
              return {...carry, [field.propertyPath]: DateCast}
            case PSViewFieldType.Checkbox:
                return {...carry, [field.propertyPath]: BooleanCast}
            default:
              break;
          }

          return {...carry}
        },
        {},
      );
      this.castsCache = result;
    }

    return this.castsCache;
  }

  get repositoryUuid () {
    return this.constructor.entity;
  }

  get metaRepository () {
    return useRepo(MetaRepository).find(this.repositoryUuid);
  }
}
