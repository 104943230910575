import { TableView, FormView, DetailView, Meta, MetaRepository, ViewField, ViewFieldConfiguration } from './Internal';
import { PSViewType } from '@/api/Api';

export class View extends Meta {
  static entity = 'views';

  static types () {
    return {
      [PSViewType.Table]: TableView,
      [PSViewType.Form]: FormView,
      [PSViewType.Detail]: DetailView
    }
  }

  static fields () {
    return {
      ...super.fields(),
      label: this.string(null),
      repository_uuid: this.attr(null),
      type: this.attr(null),
      meta_data: this.attr(null),
      repository: this.belongsTo(MetaRepository, 'repository_uuid'),
      viewFields: this.belongsToMany(ViewField, ViewFieldConfiguration, 'view_uuid', 'view_field_uuid')
    }
  }

  get filteredSortedViewFields () {
    throw new Error("Abstract Getter filteredSortedViewFields has no implementation");
  }

  get title () {
    throw new Error("Abstract Getter title has no implementation");
  }

  get titleViewField (): ViewField {
    return this.filteredSortedViewFields.find(viewField => viewField.pivot.show);
  }

  get defaultSettings() {
    if (!this.meta_data.settings) {
      return undefined;
    }

    if (!(this.meta_data.global_default_settings_uuid in this.meta_data.settings)) {
      return undefined;
    }

    return this.meta_data.settings[this.meta_data.global_default_settings_uuid].settings;
  }

  // pinia-orm don't have sorting via pivot
  get sortedViewFields () {
    return this.viewFields.sort((a, b) => {
      if (a.pivot.order_column < b.pivot.order_column) return -1;
      if (a.pivot.order_column > b.pivot.order_column) return 1;
      return 0;
    });
  }

  isType(type: Number): boolean {
    return this.type === type;
  }
}
