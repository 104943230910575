import { Model } from 'pinia-orm';

export class Meta extends Model {
  static primaryKey = 'uuid';
  static loaded = ref(false);

  static fields () {
    return {
      uuid: this.attr(null)
    }
  }
}
