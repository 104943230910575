import { View, ViewField, MetaRepository } from '../Internal';
import { PSFilterMatchMode, PSViewFieldType } from '@/api/Api';
import { Model } from 'pinia-orm';

export class BelongsToViewField extends ViewField {
  static entity = 'belongs-to-view-fields';
  static baseEntity = 'view-fields';

  static fields () {
    return {
      ...super.fields(),
    }
  }

  get propertyPath (): string {
    if (!this.relatedRepository.dataRepositoryLoaded) {
      this.relatedRepository.useRepo();
    }
    return this.uuid;
  }

  get dataType (): string {
    return 'numeric';
  }

  get sortPropertyPath (): string {
    return this.propertyPath + '.' + this.titleViewFieldUuid;
  }

  get sortable (): boolean {
    return true;
  }

  get filterPropertyPath (): string {
    return this.relationFieldUuid;
  }

  get filterable (): boolean {
    return true;
  }

  get showFilterMatchModes (): boolean {
    return true;
  }

  get filterMatchModeOptions (): Array<Object> | undefined {
    return [
      { value: PSFilterMatchMode.EQUALS, label: 'Rovná se' },
      { value: PSFilterMatchMode.NOT_EQUALS, label: 'Nerovná se' },
      { value: PSFilterMatchMode.IN, label: 'Rovná se některému' },
      { value: PSFilterMatchMode.NOT_IN, label: 'Nerovná se žádnému' },
      { value: PSFilterMatchMode.IS_EMPTY, label: 'Je nevyplněný' },
      { value: PSFilterMatchMode.IS_NOT_EMPTY, label: 'Je vyplněný' },
    ];
  }

  get relationFieldUuid (): string {
    return this.meta_data.relation_field_uuid;
  }

  get oppositeRelationUuid (): string {
    return this.meta_data.opposite_relation_uuid;
  }

  get oppositeRelation () {
    return useRepo(ViewField).find(this.oppositeRelationUuid);
  }

  get foreignRepositoryUuid (): string {
    return this.meta_data.foreign_repository_uuid;
  }

  get tableViewUuid (): string {
    return this.meta_data.table_view_uuid;
  }

  get detailViewUuid (): string {
    return this.meta_data.detail_view_uuid;
  }

  get options () {
    return this.relatedRepository?.useRepo().all();
  }

  get relatedRepository () {
    return useRepo(MetaRepository).find(this.foreignRepositoryUuid);
  }

  get titleViewField () {
    return useRepo(View).with('viewFields').find(this.tableViewUuid).titleViewField;
  }

  get titleViewFieldUuid () {
    return this.titleViewField.uuid;
  }

  getRelatedResource (value: number) {
    return this.relatedRepository?.useRepo().find(value);
  }

  formatToPlainString (value: Model | null): string {
    if (value == null) return '';

    const titleViewField = this.titleViewField;

    return !titleViewField.isType(PSViewFieldType.BelongsTo) ? (titleViewField.formatToPlainString(titleViewField.getValueFromResource(value)) ?? value.id) : value.id;
  }
}
