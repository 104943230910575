import { Application, View, Meta} from './Internal';

export class MenuItem extends Meta {
  static entity = 'menu-items';

  static fields () {
    return {
      ...super.fields(),
      id: this.attr(null),
      label: this.attr(null),
      order_column: this.attr(null),
      application_uuid: this.attr(null),
      view_uuid: this.attr(null),
      application: this.belongsTo(Application, 'application_uuid'),
      view: this.belongsTo(View, 'view_uuid')
    }
  }
}
