import { View, ViewField } from '../Internal';
import { PSViewFieldType } from '@/api/Api';
import { Model } from 'pinia-orm';

export class DetailView extends View {
  static entity = 'detail-views';
  static baseEntity = 'views';

  static fields () {
    return {
      ...super.fields(),
    }
  }

  get formViewUuidForUpdate () {
    return this.meta_data.Update.goToViewUuid;
  }

  get tableViewUuidForDelete () {
    return this.meta_data.Delete.goToViewUuid;
  }

  get filteredSortedViewFields () {
    return this.sortedViewFields.filter((viewField: ViewField) => !viewField.isType(PSViewFieldType.HasMany) && viewField.pivot.show);
  }

  get filteredSortedRelationViewFields () {
    return this.sortedViewFields.filter((viewField: ViewField) => viewField.isType(PSViewFieldType.HasMany) && viewField.pivot.show);
  }

  getTitle (resource: Model) {
    return this.repository.singular_label + (this.titleViewField && resource ? ': ' + this.titleViewField.formatToPlainString(this.titleViewField.getValueFromResource(resource)) : '');
  }
}
