import { Meta, View, RepositoryAction, ViewField, DataModel } from './Internal';
import { Model } from 'pinia-orm';

export class MetaRepository extends Meta {
  static entity = 'meta-repositories';

  static fields () {
    return {
      ...super.fields(),
      singular_label: this.string(null),
      singular_label_accusative: this.string(null),
      plural_label: this.string(null),
      plural_label_accusative: this.string(null),
      _dataModel: this.attr(null),
      dataModelInitialized: this.boolean(false),
      dataRepositoryLoaded: this.boolean(false),
      views: this.hasMany(View, 'repository_uuid'),
      viewFields: this.hasMany(ViewField, 'repository_uuid'),
      actions: this.hasMany(RepositoryAction, 'repository_uuid'),
    }
  }

  get allResourcesWithTopLevelRelations () {
    return this.useRepo().withAll().get();
  }

  get allResources () {
    return this.useRepo().all();
  }

  getResourceWithTopLevelRelations (id: Number): Model {
    return this.useRepo().withAll().find(id);
  }

  getResource (id: Number): Model {
    return this.useRepo().find(id);
  }

  useRepo () {
    if (!this.dataRepositoryLoaded) {
      const { initRepository } = usePSDBRepository();
      initRepository(this.dataModel);
      this.dataRepositoryLoaded = true;
      useRepo(MetaRepository).save(this);
    }
    return useRepo(this.dataModel);
  }

  get dataModel () {
    if (!this._dataModel) {
      //this is needed or it will not work. It will not import DataModel on compilation.
      DataModel.wtf;
      this._dataModel =  eval(
        `(class ${this.uuid} extends DataModel {
          static entity = '${this.uuid}';
        })`
      );

      this.dataModelInitialized = true;
      useRepo(MetaRepository).save(this);
    }

    return this._dataModel;
  }
}
