import { ViewField } from '../Internal';

// TODO deprecated
export class ComputedViewField extends ViewField {
  static entity = 'computed-view-fields';
  static baseEntity = 'view-fields';

  static fields () {
    return {
      ...super.fields(),
    }
  }

  get propertyPath (): string {
    return this.uuid;
  }

  get dataType (): string {
    return 'text';
  }

  get sortPropertyPath (): string {
    return this.uuid;
  }

  get sortable (): boolean {
    return true;
  }

  get filterPropertyPath (): string {
    return this.uuid;
  }

  get filterable (): boolean {
    return false;
  }

  get showFilterMatchModes (): boolean {
    return false;
  }

  get filterMatchModeOptions (): Array<Object> | undefined {
    return undefined;
  }

  formatToPlainString (value: string | null): string {
    if (value == null) return '';

    return value;
  }
}
