import { Meta } from './Internal';

export class ViewFieldConfiguration extends Meta {
  static entity = 'view-field-configurations';

  static fields () {
    return {
      ...super.fields(),
      view_field_uuid: this.attr(null),
      view_uuid: this.attr(null),
      show: this.attr(null),
      order_column: this.attr(null),
    }
  }
}
