import { Meta, MetaRepository} from './Internal';

export class RepositoryAction extends Meta {
  static entity = 'repository-actions';

  static fields () {
    return {
      ...super.fields(),
      label: this.attr(null),
      repository_uuid: this.attr(null),
      repository: this.belongsTo(MetaRepository, 'repository_uuid')
    }
  }

}
