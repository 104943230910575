import { ViewField } from '../Internal';

export class NumberViewField extends ViewField {
  static entity = 'number-view-fields';
  static baseEntity = 'view-fields';

  static fields () {
    return {
      ...super.fields(),
    }
  }

  get propertyPath (): string {
    return this.uuid;
  }

  get dataType (): string {
    return 'numeric';
  }

  get sortPropertyPath (): string {
    return this.uuid;
  }

  get sortable (): boolean {
    return true;
  }

  get filterPropertyPath (): string {
    return this.uuid;
  }

  get filterable (): boolean {
    return true;
  }

  get showFilterMatchModes (): boolean {
    return true;
  }

  get filterMatchModeOptions (): Array<Object> | undefined {
    return undefined;
  }

  get prefix () {
    return this.meta_data.prefix;
  }

  get suffix () {
    return this.meta_data.suffix;
  }

  get locale () {
    return this.meta_data.locale;
  }

  get mode () {
    return this.meta_data.mode;
  }

  get currency () {
    return this.meta_data.currency;
  }

  get currencyDisplay () {
    return this.meta_data.currencyDisplay;
  }

  get min () {
    return this.meta_data.min;
  }

  get max () {
    return this.meta_data.max;
  }

  get minFractionDigits () {
    return this.meta_data.minFractionDigits;
  }

  get maxFractionDigits () {
    return this.meta_data.maxFractionDigits;
  }

  formatToPlainString (value: number): string {
    if (value != null) {
      return this.prefix + new Intl.NumberFormat(this.locale, {
        style: this.mode,
        currency: this.currency,
        currencyDisplay: this.currencyDisplay
      }).format(value) + this.suffix;
    } else {
      return '';
    }
  }
}
