import { MenuItem, Meta} from './Internal';

export class Application extends Meta {
  static entity = 'applications';

  static fields () {
    return {
      ...super.fields(),
      label: this.attr(null),
      icon: this.attr(null),
      order_column: this.attr(null),
      menuItems: this.hasMany(MenuItem, 'application_uuid')
    }
  }
}
