import { default as _91id_93unKZkMtInyMeta } from "/build/pages/[tenantUuid]/[applicationUuid]/[viewUuid]/[id].vue?macro=true";
import { default as _91viewUuid_93UcTr1GmhiuMeta } from "/build/pages/[tenantUuid]/[applicationUuid]/[viewUuid].vue?macro=true";
import { default as indexS4oZJ0N4cAMeta } from "/build/pages/[tenantUuid]/[applicationUuid]/index.vue?macro=true";
import { default as _91lectureId_9335434knBRrMeta } from "/build/pages/[tenantUuid]/academy/[academyId]/[courseId]/[lectureId].vue?macro=true";
import { default as _91academyId_93n9v9gCdZcRMeta } from "/build/pages/[tenantUuid]/academy/[academyId].vue?macro=true";
import { default as academy0oUJckJjwJMeta } from "/build/pages/[tenantUuid]/academy.vue?macro=true";
import { default as indexuwKUiaSU1lMeta } from "/build/pages/[tenantUuid]/index.vue?macro=true";
import { default as _91tenantUuid_937qXCVqgmwcMeta } from "/build/pages/[tenantUuid].vue?macro=true";
import { default as index2yY1hMMS9YMeta } from "/build/pages/index.vue?macro=true";
import { default as _91slug_93ZcXeYaOJwCMeta } from "/build/pages/legal/[slug].vue?macro=true";
import { default as forgot_45passwordsEgLlVNHLoMeta } from "/build/pages/login/forgot-password.vue?macro=true";
import { default as indexKxn8JNUtLqMeta } from "/build/pages/login/index.vue?macro=true";
import { default as reset_45passwordHD6ucZQvEzMeta } from "/build/pages/login/reset-password.vue?macro=true";
import { default as loginSPHB0sFF0AMeta } from "/build/pages/login.vue?macro=true";
import { default as logoutIARkfhKEN0Meta } from "/build/pages/logout.vue?macro=true";
import { default as no_45tenantEnVFywZYVIMeta } from "/build/pages/no-tenant.vue?macro=true";
export default [
  {
    path: _91tenantUuid_937qXCVqgmwcMeta?.path ?? "/:tenantUuid()",
    children: [
  {
    name: _91viewUuid_93UcTr1GmhiuMeta?.name ?? "tenantUuid-applicationUuid-viewUuid",
    path: _91viewUuid_93UcTr1GmhiuMeta?.path ?? ":applicationUuid()/:viewUuid()",
    children: [
  {
    name: _91id_93unKZkMtInyMeta?.name ?? "tenantUuid-applicationUuid-viewUuid-id",
    path: _91id_93unKZkMtInyMeta?.path ?? ":id()",
    meta: _91id_93unKZkMtInyMeta || {},
    alias: _91id_93unKZkMtInyMeta?.alias || [],
    redirect: _91id_93unKZkMtInyMeta?.redirect || undefined,
    component: () => import("/build/pages/[tenantUuid]/[applicationUuid]/[viewUuid]/[id].vue").then(m => m.default || m)
  }
],
    meta: _91viewUuid_93UcTr1GmhiuMeta || {},
    alias: _91viewUuid_93UcTr1GmhiuMeta?.alias || [],
    redirect: _91viewUuid_93UcTr1GmhiuMeta?.redirect || undefined,
    component: () => import("/build/pages/[tenantUuid]/[applicationUuid]/[viewUuid].vue").then(m => m.default || m)
  },
  {
    name: indexS4oZJ0N4cAMeta?.name ?? "tenantUuid-applicationUuid",
    path: indexS4oZJ0N4cAMeta?.path ?? ":applicationUuid()",
    meta: indexS4oZJ0N4cAMeta || {},
    alias: indexS4oZJ0N4cAMeta?.alias || [],
    redirect: indexS4oZJ0N4cAMeta?.redirect || undefined,
    component: () => import("/build/pages/[tenantUuid]/[applicationUuid]/index.vue").then(m => m.default || m)
  },
  {
    name: academy0oUJckJjwJMeta?.name ?? "tenantUuid-academy",
    path: academy0oUJckJjwJMeta?.path ?? "academy",
    children: [
  {
    name: _91academyId_93n9v9gCdZcRMeta?.name ?? "tenantUuid-academy-academyId",
    path: _91academyId_93n9v9gCdZcRMeta?.path ?? ":academyId()",
    children: [
  {
    name: _91lectureId_9335434knBRrMeta?.name ?? "tenantUuid-academy-academyId-courseId-lectureId",
    path: _91lectureId_9335434knBRrMeta?.path ?? ":courseId()/:lectureId()",
    meta: _91lectureId_9335434knBRrMeta || {},
    alias: _91lectureId_9335434knBRrMeta?.alias || [],
    redirect: _91lectureId_9335434knBRrMeta?.redirect || undefined,
    component: () => import("/build/pages/[tenantUuid]/academy/[academyId]/[courseId]/[lectureId].vue").then(m => m.default || m)
  }
],
    meta: _91academyId_93n9v9gCdZcRMeta || {},
    alias: _91academyId_93n9v9gCdZcRMeta?.alias || [],
    redirect: _91academyId_93n9v9gCdZcRMeta?.redirect || undefined,
    component: () => import("/build/pages/[tenantUuid]/academy/[academyId].vue").then(m => m.default || m)
  }
],
    meta: academy0oUJckJjwJMeta || {},
    alias: academy0oUJckJjwJMeta?.alias || [],
    redirect: academy0oUJckJjwJMeta?.redirect || undefined,
    component: () => import("/build/pages/[tenantUuid]/academy.vue").then(m => m.default || m)
  },
  {
    name: indexuwKUiaSU1lMeta?.name ?? "tenantUuid",
    path: indexuwKUiaSU1lMeta?.path ?? "",
    meta: indexuwKUiaSU1lMeta || {},
    alias: indexuwKUiaSU1lMeta?.alias || [],
    redirect: indexuwKUiaSU1lMeta?.redirect || undefined,
    component: () => import("/build/pages/[tenantUuid]/index.vue").then(m => m.default || m)
  }
],
    name: _91tenantUuid_937qXCVqgmwcMeta?.name ?? undefined,
    meta: _91tenantUuid_937qXCVqgmwcMeta || {},
    alias: _91tenantUuid_937qXCVqgmwcMeta?.alias || [],
    redirect: _91tenantUuid_937qXCVqgmwcMeta?.redirect || undefined,
    component: () => import("/build/pages/[tenantUuid].vue").then(m => m.default || m)
  },
  {
    name: index2yY1hMMS9YMeta?.name ?? "index",
    path: index2yY1hMMS9YMeta?.path ?? "/",
    meta: index2yY1hMMS9YMeta || {},
    alias: index2yY1hMMS9YMeta?.alias || [],
    redirect: index2yY1hMMS9YMeta?.redirect || undefined,
    component: () => import("/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZcXeYaOJwCMeta?.name ?? "legal-slug",
    path: _91slug_93ZcXeYaOJwCMeta?.path ?? "/legal/:slug()",
    meta: _91slug_93ZcXeYaOJwCMeta || {},
    alias: _91slug_93ZcXeYaOJwCMeta?.alias || [],
    redirect: _91slug_93ZcXeYaOJwCMeta?.redirect || undefined,
    component: () => import("/build/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    path: loginSPHB0sFF0AMeta?.path ?? "/login",
    children: [
  {
    name: forgot_45passwordsEgLlVNHLoMeta?.name ?? "login-forgot-password",
    path: forgot_45passwordsEgLlVNHLoMeta?.path ?? "forgot-password",
    meta: forgot_45passwordsEgLlVNHLoMeta || {},
    alias: forgot_45passwordsEgLlVNHLoMeta?.alias || [],
    redirect: forgot_45passwordsEgLlVNHLoMeta?.redirect || undefined,
    component: () => import("/build/pages/login/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexKxn8JNUtLqMeta?.name ?? "login",
    path: indexKxn8JNUtLqMeta?.path ?? "",
    meta: indexKxn8JNUtLqMeta || {},
    alias: indexKxn8JNUtLqMeta?.alias || [],
    redirect: indexKxn8JNUtLqMeta?.redirect || undefined,
    component: () => import("/build/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordHD6ucZQvEzMeta?.name ?? "login-reset-password",
    path: reset_45passwordHD6ucZQvEzMeta?.path ?? "reset-password",
    meta: reset_45passwordHD6ucZQvEzMeta || {},
    alias: reset_45passwordHD6ucZQvEzMeta?.alias || [],
    redirect: reset_45passwordHD6ucZQvEzMeta?.redirect || undefined,
    component: () => import("/build/pages/login/reset-password.vue").then(m => m.default || m)
  }
],
    name: loginSPHB0sFF0AMeta?.name ?? undefined,
    meta: loginSPHB0sFF0AMeta || {},
    alias: loginSPHB0sFF0AMeta?.alias || [],
    redirect: loginSPHB0sFF0AMeta?.redirect || undefined,
    component: () => import("/build/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutIARkfhKEN0Meta?.name ?? "logout",
    path: logoutIARkfhKEN0Meta?.path ?? "/logout",
    meta: logoutIARkfhKEN0Meta || {},
    alias: logoutIARkfhKEN0Meta?.alias || [],
    redirect: logoutIARkfhKEN0Meta?.redirect || undefined,
    component: () => import("/build/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: no_45tenantEnVFywZYVIMeta?.name ?? "no-tenant",
    path: no_45tenantEnVFywZYVIMeta?.path ?? "/no-tenant",
    meta: no_45tenantEnVFywZYVIMeta || {},
    alias: no_45tenantEnVFywZYVIMeta?.alias || [],
    redirect: no_45tenantEnVFywZYVIMeta?.redirect || undefined,
    component: () => import("/build/pages/no-tenant.vue").then(m => m.default || m)
  }
]