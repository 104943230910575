import { Model } from 'pinia-orm';

export const usePSDBRepository = () => {
  const { PSDBApi } = usePSDBApi();
  const { registerRepositoryCallbacks } = useWebSocket();
  const toast = useGlobalToast();

  const defaultStartPage = 1;
  const defaultPerPage = 1000;

  const initRepository = async (repositoryModel: Model, loadedCallback?: () => void) => {
    // This do empty query on empty repository. This guarantees, that even empty repository is registered to Pinia store.
    useRepo(repositoryModel).all();

    await loadRepositoryFromServer(repositoryModel, defaultStartPage, defaultPerPage);

    // Attach repository to websocket
    registerRepositoryCallbacks(repositoryModel,
      (data) => {
        console.log('Websocket: created');
        upsertResourceToLocalRepository(repositoryModel, data.resource);
      },
      (data) => {
        console.log('Websocket: updated');
        upsertResourceToLocalRepository(repositoryModel, data.resource);
      },
      (data) => {
        console.log('Websocket: deleted');
        removeResourceFromLocalRepository(repositoryModel, data.resourceId);
      }
    )

    if (loadedCallback) loadedCallback();
  }

  const loadRepositoryFromServer = async (repositoryModel: Model, page: number, perPage: number) => {
    const { data, error, statusCode } = await PSDBApi(getApiPathFromRepositoryModel(repositoryModel)+`?page=${page}&perPage=${perPage}`).get().json();
      if (statusCode.value != 200) {
        toast.add({
          severity: 'error',
          summary: 'Chyba při načítaní dat.',
          detail: error.value.message,
          life: 3000,
        });
      } else {
        upsertResourcesToLocalRepository(repositoryModel, data.value.data);

        if (data.value.meta.current_page < data.value.meta.last_page) {
          await loadRepositoryFromServer(repositoryModel, ++page, perPage);
        }
      }
  }

  const getApiPathFromRepositoryModel = (repositoryModel: Model): string => {
    return `${repositoryModel.entity}`;
  }

  const upsertResourcesToLocalRepository = (repositoryModel: Model, resources: Array) => {
    useRepo(repositoryModel).save(jsonApiArrayToPiniaORMRepository(resources));
  }

  const upsertResourceToLocalRepository = (repositoryModel: Model, resource: Object) => {
    return useRepo(repositoryModel).save(jsonApiObjectToPiniaORMRepository(resource));
  }

  const removeResourceFromLocalRepository = (repositoryModel: Model, resourceId: number) => {
    useRepo(repositoryModel).destroy(resourceId);
  }

  const jsonApiArrayToPiniaORMRepository = (data) => {
    return data.map((item) => jsonApiObjectToPiniaORMRepository(item));
  }

  const jsonApiObjectToPiniaORMRepository = (data) => {
    let result = {
      id: data.id
    };

    if (data.attributes) {
      result = {
        ...result,
        ...data.attributes,
      }
    }

    if (data.relationships) {
      for (const key in data.relationships) {
        if (Object.prototype.hasOwnProperty.call(data.relationships, key)) {
          if (data.relationships[key] == null) {
            result = {
              ...result,
              [key]: null
            }
          } else if (Array.isArray(data.relationships[key])) {
            result = {
              ...result,
              [key]: jsonApiArrayToPiniaORMRepository(data.relationships[key])
            }
          } else {
            result = {
              ...result,
              [key]: jsonApiObjectToPiniaORMRepository(data.relationships[key])
            }
          }
        }
      }
    }

    return result;
  }

  return {
    initRepository,
    getApiPathFromRepositoryModel,
    upsertResourceToLocalRepository,
    removeResourceFromLocalRepository
  }
}
