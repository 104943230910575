import { BelongsToViewField, SingleLineViewField, Meta, View, ViewFieldConfiguration, MetaRepository, HasManyViewField, SingleSelectViewField, PasswordViewField, DateTimeViewField, NumberViewField, EmailViewField, TextAreaViewField, UrlViewField, ComputedViewField, PhoneViewField, AttachmentViewField, CheckboxViewField, RatingViewField, ProgressBarViewField, CountryViewField, HasOneViewField } from './Internal';
import { PSViewFieldType } from '@/api/Api';
import { Model } from 'pinia-orm';

export class ViewField extends Meta {
  static entity = 'view-fields';

  static types () {
    return {
      [PSViewFieldType.SingleLine]: SingleLineViewField,
      [PSViewFieldType.BelongsTo]: BelongsToViewField,
      [PSViewFieldType.HasOne]: HasOneViewField,
      [PSViewFieldType.HasMany]: HasManyViewField,
      // [PSViewFieldType.BelongsToMany]: SingleLineViewField,
      [PSViewFieldType.SingleSelect]: SingleSelectViewField,
      [PSViewFieldType.Password]: PasswordViewField,
      [PSViewFieldType.DateTime]: DateTimeViewField,
      [PSViewFieldType.Number]: NumberViewField,
      [PSViewFieldType.Email]: EmailViewField,
      [PSViewFieldType.TextArea]: TextAreaViewField,
      [PSViewFieldType.Url]: UrlViewField,
      [PSViewFieldType.Computed]: ComputedViewField,
      [PSViewFieldType.Phone]: PhoneViewField,
      [PSViewFieldType.Attachment]: AttachmentViewField,
      [PSViewFieldType.Checkbox]: CheckboxViewField,
      [PSViewFieldType.Rating]: RatingViewField,
      [PSViewFieldType.ProgressBar]: ProgressBarViewField,
      [PSViewFieldType.Country]: CountryViewField,
    }
  }

  static fields () {
    return {
      ...super.fields(),
      label: this.attr(null),
      required: this.attr(null),
      computed: this.attr(null),
      meta_data: this.attr(null),
      type: this.attr(null),
      repository_uuid: this.attr(null),
      repository: this.belongsTo(MetaRepository, 'repository_uuid'),
      views: this.belongsToMany(View, ViewFieldConfiguration, 'view_field_uuid', 'view_uuid')
    }
  }

  get propertyPath (): string {
    throw new Error("Abstract Getter propertyPath has no implementation");
  }

  get dataType (): string {
    throw new Error("Abstract Getter dataType has no implementation");
  }

  get sortPropertyPath (): string {
    throw new Error("Abstract Getter sortPropertyPath has no implementation");
  }

  get sortable (): boolean {
    throw new Error("Abstract Getter sortable has no implementation");
  }

  get filterPropertyPath (): string {
    throw new Error("Abstract Getter filterPropertyPath has no implementation");
  }

  get filterable (): boolean {
    throw new Error("Abstract Getter filterable has no implementation");
  }

  get showFilterMatchModes (): boolean {
    throw new Error("Abstract Getter showFilterMatchModes has no implementation");
  }

  get filterMatchModeOptions (): Array<Object> | undefined {
    throw new Error("Abstract Getter filterMatchModeOptions has no implementation");
  }

  formatToPlainString (value: any): string {
    throw new Error("Abstract Method formatToPlainString has no implementation");
  }

  isType(type: Number): boolean {
    return this.type === type;
  }

  get isRelationship(): boolean {
    return this.type === PSViewFieldType.BelongsTo || this.type === PSViewFieldType.HasMany || this.type === PSViewFieldType.HasOne;
  }

  getValueFromResource(resource: Model): any {
    return resource[this.propertyPath];
  }
}
