import VueHotjar from "vue-hotjar-next";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  if (config.public.environment == 'production') {
    nuxtApp.vueApp.use(VueHotjar, {
      id: Number(config.public.hotjarSiteId),
      isProduction: true,
    });
  }
});
