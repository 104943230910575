import { FilterService } from 'primevue/api';
import { PSFilterMatchMode, PSFilterSpecialValue, PSDateTimeFilterMode } from '@/api/Api';
import { ObjectUtils } from 'primevue/utils';

export const usePSFilters = () => {
  const { userId } = useProfile();

  const inFilter = (value, filter): boolean => {
    if (filter === undefined || filter === null || filter.length === 0) {
      return true;
    }

    for (let i = 0; i < filter.length; i++) {
      if (ObjectUtils.equals(value, filter[i], '') || (filter[i] == PSFilterSpecialValue.BELONGS_TO_IN_LOGGED_IN_USER && userId.value == value)) {
        return true;
      }
    }

    return false;
  };

  const equalsFilter = (value, filter, filterLocale): boolean => {
    if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    return ObjectUtils.removeAccents(value.toString()).toLocaleLowerCase(filterLocale) == ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale)|| (filter == PSFilterSpecialValue.BELONGS_TO_IN_LOGGED_IN_USER && userId.value == value);
  };

  const isEmpty = (value): boolean => {
    return value === null || value === undefined;
  };

  const registerPSFilters = () => {
    FilterService.register(PSFilterMatchMode.EQUALS, (value, filter, filterLocale): boolean => {
      return equalsFilter(value, filter, filterLocale);
    });

    FilterService.register(PSFilterMatchMode.NOT_EQUALS, (value, filter, filterLocale): boolean => {
      return !equalsFilter(value, filter, filterLocale);
    });

    FilterService.register(PSFilterMatchMode.IN, (value, filter): boolean => {
      return inFilter(value, filter);
    });

    FilterService.register(PSFilterMatchMode.NOT_IN, (value, filter): boolean => {
      return !inFilter(value, filter);
    });

    FilterService.register(PSFilterMatchMode.IS_EMPTY, (value): boolean => {
      return isEmpty(value);
    });

    FilterService.register(PSFilterMatchMode.IS_NOT_EMPTY, (value): boolean => {
      return !isEmpty(value);
    });

    FilterService.register(PSFilterMatchMode.DATE_IS, (value, filter): boolean => {
      if (filter === undefined || filter === null) {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      let result = true;

      if (PSDateTimeFilterMode.STATIC == filter.mode) {
        if (filter.staticDate && filter.staticDate.toDateString() !== value.toDateString()) {
          result = false;
        }

        //FIXME This doesnt work with seconds disabled, because filter do not know about it
        if (filter.staticTime && filter.staticTime.toTimeString() !== value.toTimeString()) {
          result = false;
        }
      } else if (PSDateTimeFilterMode.DYNAMIC == filter.mode) {
        const dynamicValue = new Date();
        dynamicValue.setHours(0,0,0,0);
        dynamicValue.setDate(dynamicValue.getDate() + filter.dynamicValue);

        if (dynamicValue.toDateString() !== value.toDateString()) {
          result = false;
        }
      }

      return result;
    });

    FilterService.register(PSFilterMatchMode.DATE_IS_NOT, (value, filter): boolean => {
      if (filter === undefined || filter === null) {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      let result = true;

      if (PSDateTimeFilterMode.STATIC == filter.mode) {
        if (filter.staticDate && filter.staticDate.toDateString() === value.toDateString()) {
          result = false;
        }

        //FIXME This doesnt work with seconds disabled, because filter do not know about it
        if (filter.staticTime && filter.staticTime.toTimeString() === value.toTimeString()) {
          result = false;
        }
      } else if (PSDateTimeFilterMode.DYNAMIC == filter.mode) {
        const dynamicValue = new Date();
        dynamicValue.setHours(0,0,0,0);
        dynamicValue.setDate(dynamicValue.getDate() + filter.dynamicValue);

        if (dynamicValue.toDateString() === value.toDateString()) {
          result = false;
        }
      }

      return result;
    });

    FilterService.register(PSFilterMatchMode.DATE_BEFORE, (value, filter): boolean => {
      if (filter === undefined || filter === null) {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      if (PSDateTimeFilterMode.STATIC == filter.mode) {
        if (filter.staticDate && filter.staticTime) {
          const finalDate = new Date(filter.staticDate.getTime());
          finalDate.setHours(filter.staticTime.getHours(), filter.staticTime.getMinutes(), filter.staticTime.getSeconds());

          return finalDate.getTime() > value.getTime();
        }

        if (filter.staticDate) {
          return filter.staticDate.getTime() > value.getTime();
        }

        //FIXME This doesnt work with seconds disabled, because filter do not know about it
        if (filter.staticTime) {
          const copyValue = new Date(value.getTime());
          const msSinceMidnightValue = copyValue.getTime() - copyValue.setHours(0,0,0,0);

          const filterValue = new Date(filter.staticTime.getTime());
          const msSinceMidnightFilter = filterValue.getTime() - filterValue.setHours(0,0,0,0);

          return msSinceMidnightFilter > msSinceMidnightValue;
        }
      } else if (PSDateTimeFilterMode.DYNAMIC == filter.mode) {
        const dynamicValue = new Date();
        dynamicValue.setHours(0,0,0,0);
        dynamicValue.setDate(dynamicValue.getDate() + filter.dynamicValue);

        return dynamicValue.getTime() > value.getTime();
      }

      return false;
    });

    FilterService.register(PSFilterMatchMode.DATE_AFTER, (value, filter): boolean => {
      if (filter === undefined || filter === null) {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      if (PSDateTimeFilterMode.STATIC == filter.mode) {
        if (filter.staticDate && filter.staticTime) {
          const finalDate = new Date(filter.staticDate.getTime());
          finalDate.setHours(filter.staticTime.getHours(), filter.staticTime.getMinutes(), filter.staticTime.getSeconds());

          return finalDate.getTime() < value.getTime();
        }

        if (filter.staticDate) {
          return filter.staticDate.getTime() < value.getTime();
        }

        //FIXME This doesnt work with seconds disabled, because filter do not know about it
        if (filter.staticTime) {
          const copyValue = new Date(value.getTime());
          const msSinceMidnightValue = copyValue.getTime() - copyValue.setHours(0,0,0,0);

          const filterValue = new Date(filter.staticTime.getTime());
          const msSinceMidnightFilter = filterValue.getTime() - filterValue.setHours(0,0,0,0);

          return msSinceMidnightFilter < msSinceMidnightValue;
        }
      } else if (PSDateTimeFilterMode.DYNAMIC == filter.mode) {
        const dynamicValue = new Date();
        dynamicValue.setHours(24,0,0,0);
        dynamicValue.setDate(dynamicValue.getDate() + filter.dynamicValue);

        return dynamicValue.getTime() < value.getTime();
      }

      return false;
    });
  };

  return {
    registerPSFilters
  }
};
