const PSFilterMatchMode = {
  DATE_IS: 'psDateIs',
  DATE_IS_NOT: 'psDateIsNot',
  DATE_BEFORE: 'psDateBefore',
  DATE_AFTER: 'psDateAfter',
  EQUALS: 'psEquals',
  NOT_EQUALS: 'psNotEquals',
  IN: 'psIn',
  NOT_IN: 'psNotIn',
  IS_EMPTY: 'psIsEmpty',
  IS_NOT_EMPTY: 'psIsNotEmpty'
};

export default PSFilterMatchMode;
