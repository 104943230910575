import { ViewField } from '../Internal';
import { PSFilterMatchMode } from '@/api/Api';

export class DateTimeViewField extends ViewField {
  static entity = 'date-time-view-fields';
  static baseEntity = 'view-fields';

  static fields () {
    return {
      ...super.fields(),
    }
  }

  get propertyPath (): string {
    return this.uuid;
  }

  get dataType (): string {
    return 'date';
  }

  get sortPropertyPath (): string {
    return this.uuid;
  }

  get sortable (): boolean {
    return true;
  }

  get filterPropertyPath (): string {
    return this.uuid;
  }

  get filterable (): boolean {
    return true;
  }

  get showFilterMatchModes (): boolean {
    return true;
  }

  get filterMatchModeOptions (): Array<Object> | undefined {
    return [
      { value: PSFilterMatchMode.DATE_IS, label: 'Datum je'},
      { value: PSFilterMatchMode.DATE_IS_NOT, label: 'Datum není'},
      { value: PSFilterMatchMode.DATE_BEFORE, label: 'Dřívější datum než'},
      { value: PSFilterMatchMode.DATE_AFTER, label: 'Pozdnější datum než'}
    ];
  }

  get timeOnly (): boolean {
    return this.meta_data.time_only;
  }

  get viewType (): string {
    return this.meta_data.view_type;
  }

  get showTime (): boolean {
    return this.meta_data.show_time;
  }

  get showSeconds (): boolean {
    return this.meta_data.show_seconds;
  }

  formatToPlainString (value: Date): string {
    if (!value) {
      return '';
    }

    const format = {};

    if (!this.timeOnly) {
      switch (this.viewType) {
        case 'date':
          format.day = 'numeric';
        case 'month':
          format.month = 'numeric';
        case 'year':
          format.year = 'numeric';
        default:
          break;
      }
    }

    if (this.showTime) {
      switch (this.showSeconds) {
        case true:
          format.second = 'numeric';
        case false:
          format.hour = 'numeric';
          format.minute = 'numeric';
        default:
          break;
      }
    }

    return new Intl.DateTimeFormat('cs', format).format(value);
  }

  get format () {
    switch (this.viewType) {
        case 'date':
          return 'd.m.yy';
        case 'month':
          return 'm/yy';
        case 'year':
          return 'yy';
        default:
          return 'd.m.yy';
      }
  }
}
