const PSViewFieldType = {
  SingleLine: 1,
  BelongsTo: 2,
  HasOne: 3,
  HasMany: 4,
  BelongsToMany: 5,
  SingleSelect: 6,
  Password: 7,
  DateTime: 8,
  Number: 9,
  Email: 10,
  TextArea: 11,
  Url: 12,
  Computed: 13,
  Phone: 14,
  Attachment: 15,
  Checkbox: 16,
  Rating: 17,
  ProgressBar: 18,
  Country: 19
};

export default PSViewFieldType;
