import { Model } from 'pinia-orm';
import { MetaRepository, View } from '~/models/Models';
import { getActivePinia } from 'pinia';
import { PSViewFieldType } from '@/api/Api';

export const usePSDBData = () => {
  const { getApiPathFromRepositoryModel, upsertResourceToLocalRepository, removeResourceFromLocalRepository } = usePSDBRepository();
  const { leaveAllChannels } = useWebSocket();
  const { PSDBApi } = usePSDBApi();
  const toast = useGlobalToast();
  const confirm = useGlobalConfirm();
  const router = useRouter();

  const submitChanges = async (view: View, resourceId: number | null = null, attributes: Object, relationships: Object, files: Map<string, File>) => {
    const { error, data } = resourceId ? await updateResource(view.repository.dataModel, resourceId, attributes, relationships, files) : await storeResource(view.repository.dataModel, attributes, relationships, files);

    let errorBag = null;

    if (error.value) {
      errorBag = error.value.errorBag;
    } else {
      updateRelationships(view, data.value.data.id, relationships);
    }

    return {
      errorBag,
      data
    }
  }

  const updateRelationships = (view: View, resourceId: Number, relationships: Object) => {
    const updatedResource = view.repository.getResourceWithTopLevelRelations(resourceId);
    Object.keys(relationships).forEach((key) => {

      const field = view.filteredSortedViewFields.find(field => field.uuid == key);


      if (field.isType(PSViewFieldType.BelongsTo)) {
        // if opposite relation is HasOne and state.relationships[field.uuid] is not null, we need to check if there exist relation already and if so, disconnect it.
        if (field.oppositeRelation.isType(PSViewFieldType.HasOne) && relationships[field.uuid]) {
          const $actualModel = view.repository.useRepo().where(field.relationFieldUuid, relationships[field.uuid].id).where('id', (value) => value != resourceId).first();
          if ($actualModel) {
            $actualModel[field.relationFieldUuid] = null;
            view.repository.useRepo().save($actualModel);
          }
        }

        const resource = view.repository.getResource(resourceId);
        resource[field.relationFieldUuid] = relationships[field.uuid] ? relationships[field.uuid].id : null;
        view.repository.useRepo().save(resource);

        // TODO tu chyba ak to je normalna belongs to relace. To nefunguje na bulk inak.
      }

      if (field.isType(PSViewFieldType.HasOne)) {
        // check if relation has value or should be null
        const relatedResorce = updatedResource[field.uuid]

        // check if new relation is not null
        if (relationships[field.uuid]) {
          const relatedModelId = relationships[field.uuid].id;
          // check if relation exists
          if (relatedResorce) {
            // check if not same and update
            if (relatedResorce.id != relatedModelId) {
              const relatedResorce = view.repository.getResourceWithTopLevelRelations(resourceId);
              relatedResorce[field.relationFieldUuid] = null;
              field.relatedRepository.useRepo().save(relatedResorce);

              const newRelatedResource = field.relatedRepository.useRepo().find(relatedModelId);
              newRelatedResource[field.relationFieldUuid] = updatedResource.id;
              field.relatedRepository.useRepo().save(newRelatedResource);
            }
          } else { // relation do not exist. just create new
            const newRelatedResource = field.relatedRepository.useRepo().find(relatedModelId);
            newRelatedResource[field.relationFieldUuid] = updatedResource.id;
            field.relatedRepository.useRepo().save(newRelatedResource);
          }
        } else {
          // check if not allready null and set to null
          if (relatedResorce) {
            relatedResorce[field.relationFieldUuid] = null;
            field.relatedRepository.useRepo().save(relatedResorce);
          }
        }
      }
    });
  };

  const storeResource = async (repository: Model, attributes: Object, relationships: Object, files: Map<string, File>) => {
    const { data, error, statusCode } = await PSDBApi(getApiPathFromRepositoryModel(repository)).post(Object.assign({}, {
      ...attributes,
      relationships
    }))
    .json();

    if (statusCode.value != 201) {
      toast.add({
        severity: 'error',
        summary: 'Chyba při ukládaní dat.',
        detail: error.value.message,
        life: 3000,
      });
    } else {
      const resource = upsertResourceToLocalRepository(repository, data.value.data);

      toast.add({
        severity: 'success',
        summary: 'Záznam byl uložen.',
        life: 3000,
      });

      if (files.size) {
        console.log('Uploading files');
        files.forEach(async (value, key) => {
          const result = await attachFileToResource(repository, resource.id, key, value);
        });
      }
    }

    return {
      data,
      error
    }
  }

  const updateResource = async (repository: Model, resourceId: number, attributes: Object, relationships: Object, files: Map<string, File>) => {
    const { data, error, statusCode } = await PSDBApi(getApiPathFromRepositoryModel(repository) + '/' + resourceId)
      .patch(Object.assign({}, {
        ...attributes,
        relationships
      }))
      .json();

    if (statusCode.value != 200) {
      toast.add({
        severity: 'error',
        summary: 'Chyba při ukládaní dat.',
        detail: error.value.message,
        life: 3000,
      });
    } else {
      const resource = upsertResourceToLocalRepository(repository, data.value.data);

      toast.add({
        severity: 'success',
        summary: 'Záznam byl uložen.',
        life: 3000,
      });

      if (files.size) {
        console.log('Uploading files');
        files.forEach(async (value, key) => {
          const result = await attachFileToResource(repository, resource.id, key, value);
        });
      }
    }

    return {
      error,
      data
    }
  }

  const updateBulk = async (view: View, ids: Array<number>, attributes: Object, relationships: Object) => {
    const payload = ids.map((id) => Object.assign({}, {
      id,
      ...attributes,
      relationships
    }));

    const { data, error, statusCode } = await PSDBApi(getApiPathFromRepositoryModel(view.repository.dataModel) + '/bulk/update')
      .post(JSON.stringify(payload), 'application/json')
      .json();

    if (statusCode.value != 200) {
      toast.add({
        severity: 'error',
        summary: 'Chyba při ukládaní dat.',
        detail: error.value.message,
        life: 3000,
      });
    } else {
      ids.forEach((id) => {
        const resource = upsertResourceToLocalRepository(view.repository.dataModel, Object.assign({}, {
          id,
          ...attributes,
        }));

        updateRelationships(view, id, relationships);
      });

      toast.add({
        severity: 'success',
        summary: 'Záznamy byly uloženy.',
        life: 3000,
      });
    }

    return {
      error,
      data
    }
  }

  const callRepositoryAction = async (repository: Model, actionUuid: string, repositories: Array<number>) => {
    const { data, error, statusCode } = await PSDBApi(getApiPathFromRepositoryModel(repository) + '/actions?action=' + actionUuid).post({ repositories }).json();

    if (statusCode.value != 200) {
      toast.add({
        severity: 'error',
        summary: 'Chyba při vykonávaní akce',
        life: 3000,
      });
    } else {
      toast.add({
        severity: 'success',
        summary: 'Akce byla vykonána.',
        life: 3000,
      });
    }

    return {
      error,
      data,
      statusCode
    }
  }

  const attachFileToResource = async (repository: Model, resourceId: number, fieldUuid: string, file: File) => {
    const formData = new FormData();
    formData.append(fieldUuid, file);

    const { data, error, statusCode } = await PSDBApi(getApiPathFromRepositoryModel(repository) + '/' + resourceId + '/actions?action=file-upload').post(formData).json();

    if (statusCode.value != 200) {
      toast.add({
        severity: 'error',
        summary: 'Chyba při ukládání souboru.',
        detail: data.value.message,
        life: 3000,
      });
    } else {
      toast.add({
        severity: 'success',
        summary: 'Soubor byl nahrán.',
        life: 3000,
      });

      upsertResourceToLocalRepository(repository, data.value.data);
    }

    return {
      error,
      data,
      statusCode
    }
  }

  const destroyResource = async (repository: Model, resourceId: number, redirect: Object | null) => {
    return confirm.require({
      message: 'Opravdu chcete odstranit tento záznam?',
      header: 'Odstranit záznam?',
      icon: 'pi pi-info-circle',
      acceptClass: 'p-button-danger',
      accept: async () => {
        const { error, statusCode } = await PSDBApi(getApiPathFromRepositoryModel(repository) + '/' + resourceId).delete().json();

        if (statusCode.value != 204) {
          toast.add({
            severity: 'error',
            summary: 'Chyba při odstraňování záznamu.',
            detail: error.value.message,
            life: 3000,
          });
        } else {
          removeResourceFromLocalRepository(repository, resourceId);
          toast.add({ severity: 'success', summary: 'Záznam odstranen.', life: 3000 });
          if (redirect) {
            router.push(redirect);
          }
        }
      }
    });
  }

  const $reset = () => {
    // disable broadcast
    leaveAllChannels();
    // flush all data from dynamic repositories
    useRepo(MetaRepository).all().forEach((repository) => {
      // Check if dynamic repo was initialized and flush it
      if (repository.dataRepositoryLoaded) {
        repository.useRepo().flush();
        delete getActivePinia().state.value[repository.useRepo().piniaStore().$id];
        repository.useRepo().piniaStore().$dispose();
      }
    });
  };

  return {
    storeResource,
    updateResource,
    updateBulk,
    submitChanges,
    destroyResource,
    callRepositoryAction,
    $reset
  }
}
