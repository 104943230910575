import { defineNuxtPlugin } from '#app';
import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  if (config.public.environment == 'production') {
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: config.public.sentryDSN,
      environment: config.public.environment,
      release: config.public.release,
      beforeSend(event, hint) {
        // Check if it is an exception, and if so, show the report dialog
        if (event.exception && event.event_id) {
          Sentry.showReportDialog({
            eventId: event.event_id,
            lang: 'cs',
            title: 'Vypadá to, že máme problémy.',
            subtitle: 'Náš tým byl informován.',
            subtitle2: "Pokud chcete pomoci, řekněte nám níže, co se stalo.",
            labelName: "Jméno",
            placeholder: "Vaše jméno",
            labelEmail: "Email",
            labelComments: "Co se stalo?",
            labelClose: "Zavřít",
            labelSubmit: "Odeslat",
            successMessage: 'Vaše zpětná vazba byla odeslána. Děkujeme!',
            errorGeneric: 'Při odesílání vašeho hlášení došlo k neznámé chybě. Prosím zkuste to znovu.',
            errorFormEntry: 'Některá pole byla neplatná. Opravte chyby a zkuste to znovu.',
            onClose() {
              navigateTo('/');
            },
          });
        }
        return event;
      },
      integrations: [
        new Sentry.Feedback({
          colorScheme: "light",
          buttonLabel: "Nahlásit chybu",
          submitButtonLabel: "Odeslat hlášení o chybě",
          formTitle: "Nahlásit chybu",
          nameLabel: "Jméno",
          namePlaceholder: "Vaše jméno",
          emailLabel: "Email",
          emailPlaceholder: "pavel@google.com",
          messageLabel: "Popis chyby",
          messagePlaceholder: "Co je to za chybu? Co jste čekali?",
          successMessageText: "Vaše zpětná vazba byla odeslána. Děkujeme!",
          useSentryUser: {
            email: "email",
            name: "name",
          },
        }),
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(useRouter()),
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: 0.0, // Capture 100% of the transactions, reduce in production!
      replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
});
