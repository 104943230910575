import { createFetch } from '@vueuse/core'

export const usePSDBApi = () => {
  const config = useRuntimeConfig();
  const { data } = useAuth();
  const { tenantUuid } = useProfile();
  const { echo } = useWebSocket();

  const PSDBApi = createFetch({
    baseUrl: config.public.basePSDBApiUrl + 'v1',
    options: {
      async beforeFetch({ options }) {
        options.headers = {
          ...options.headers,
          ...{
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + data.value.token,
            'X-Tenant': tenantUuid.value,
            'X-Socket-ID': echo.value && echo.value.socketId()
          },
        }

        return { options }
      },
      onFetchError(ctx) {
        ctx.error.message = ctx.data?.message ?? 'Neznáma chyba'; // TODO report to sentry
        ctx.error.errorBag = ctx.data?.errors ?? {};

        // Reset session and logout
        if (ctx.response?.status === 401 || ctx.response?.status === 403) {
          useAuth().signOut();
        }


        return ctx
      },
    }
  });

  return {
    PSDBApi
  }
}
