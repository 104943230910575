import { View, ViewField } from '../Internal';
import { PSViewFieldType } from '@/api/Api';

export class TableView extends View {
  static entity = 'table-views';
  static baseEntity = 'views';

  static fields () {
    return {
      ...super.fields(),
    }
  }

  get formViewUuidForStore () {
    return this.meta_data.Store.goToViewUuid;
  }

  get formViewUuidForUpdate () {
    return this.meta_data.Update.goToViewUuid;
  }

  get detailViewUuidForRedirect () {
    return this.meta_data.Show.goToViewUuid;
  }

  get filteredSortedViewFields () {
    return this.sortedViewFields.filter((viewField: ViewField) => !viewField.isType(PSViewFieldType.HasMany) && viewField.pivot.show);
  }

  get title () {
    return this.repository.plural_label;
  }

  get settings () {
    return this.meta_data.settings;
  }

  get defaultSettingsUuid () {
    return this.meta_data.global_default_settings_uuid;
  }
}
