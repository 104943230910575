import { Application, MenuItem, MetaRepository, Tag, TagGroup, View, ViewField, ViewFieldConfiguration, RepositoryAction, TableView } from '@/models/Models';
import { getActivePinia } from 'pinia';

// TODO Hack for statikum
const selectedGlobalFilter = ref(null);

export const usePSDBMeta = () => {
  const { initRepository, getApiPathFromRepositoryModel, upsertResourceToLocalRepository } = usePSDBRepository();
  const { PSDBApi } = usePSDBApi();
  const toast = useGlobalToast();

  const PSDBMetaRepositories = ref([
    MetaRepository,
    Application,
    MenuItem,
    Tag,
    TagGroup,
    View,
    ViewField,
    RepositoryAction,
    ViewFieldConfiguration
  ]);

  const initializeMetaSystem = () => {
    PSDBMetaRepositories.value.forEach((repository) => {
      initRepository(repository, () => {
        repository.loaded.value = true;
      });
    });
  }

  const metaRepositoriesLoadingState = computed(() => {
    const piniaState = getActivePinia().state.value;
    return {
      loaded: PSDBMetaRepositories.value.filter((repository) => {
        return (repository) && repository.loaded.value && (repository.entity in piniaState) && ((repository == ViewFieldConfiguration || repository == View || repository == MetaRepository || repository == ViewField || repository == Application || repository == MenuItem) ? useRepo(repository).all().length > 0 : true);
      }).length,
      total: PSDBMetaRepositories.value.length
    }
  });

  const metaRepositoriesLoaded = computed(() => {
    return metaRepositoriesLoadingState.value.loaded == metaRepositoriesLoadingState.value.total;
  })

  // TODO this could be managed via central actions system
  const createViewSettings = async (view: TableView, label: string, settings: string) => {
    const { data, error } = await PSDBApi(getApiPathFromRepositoryModel(View) + '/' + view.uuid + '/actions?action=create-view-settings-action').post({
      label,
      settings
    }).json();

    if (data.value) {
      toast.add({
        severity: 'success',
        summary: 'Filtr byl vytvořen.',
        life: 3000,
      });

      view.meta_data.settings = data.value.meta_data.settings;
      useRepo(View).save(view);
    } else {
      toast.add({
        severity: 'error',
        summary: 'Nastala neočekávana chyba.',
        life: 3000,
      });
    }
  };

  // TODO this could be managed via central actions system
  const setViewDefaultSettings = async (view: TableView, viewSettingsUuid: string) => {
    const { data, error } = await PSDBApi(getApiPathFromRepositoryModel(View) + '/' + view.uuid + '/actions?action=set-view-default-settings-action').post({
      viewSettingsUuid
    }).json();

    if (data.value) {
      toast.add({
        severity: 'success',
        summary: viewSettingsUuid ? 'Filtr byl nastaven jako výchozí.' : 'Filtr byl zrušený jako výchozí.',
        life: 3000,
      });

      view.meta_data.global_default_settings_uuid = data.value.meta_data.global_default_settings_uuid;
      useRepo(View).save(view);
    } else {
      toast.add({
        severity: 'error',
        summary: 'Nastala neočekávana chyba.',
        life: 3000,
      });
    }
  };

  // TODO this could be managed via central actions system
  const removeViewSettings = async (view: TableView, viewSettingsUuid: string) => {
    const { data, error } = await PSDBApi(getApiPathFromRepositoryModel(View) + '/' + view.uuid + '/actions?action=remove-view-settings-action').post({
      viewSettingsUuid
    }).json();

    if (data.value) {
      toast.add({
        severity: 'success',
        summary: 'Filtr byl smazán.',
        life: 3000,
      });

      view.meta_data.settings = data.value.meta_data.settings;
      useRepo(View).save(view);
    } else {
      toast.add({
        severity: 'error',
        summary: 'Nastala neočekávana chyba.',
        life: 3000,
      });
    }
  };

  // TODO this could be managed via central actions system
  const createTagInTagGroup = async (tagGroup: TagGroup, tag: object) => {
    const { data, error } = await PSDBApi(getApiPathFromRepositoryModel(TagGroup) + '/' + tagGroup.uuid + '/actions?action=create-tag-action').post({
      tagGroupUuid: tagGroup.uuid,
      ...tag
    }).json();

    if (data.value) {
      toast.add({
        severity: 'success',
        summary: 'Možnost byla vytvořena.',
        life: 3000,
      });

      return upsertResourceToLocalRepository(Tag, data.value);
    } else {
      toast.add({
        severity: 'error',
        summary: 'Nastala neočekávana chyba.',
        life: 3000,
      });
    }

    return null;
  }

  const $reset = () => {
    PSDBMetaRepositories.value.forEach((repository) => {
      useRepo(repository).flush();
    });
  }

  // TODO hack for statikum
  const router = useRouter();
  const globalFilterEnabled = computed(() => {
    return (router.currentRoute.value.name == 'tenantUuid-applicationUuid-viewUuid' && [
      'viewb173ea76bb5b4be792fc8dc381af65d8',
      'viewa3b8c2eb553a4d84a38a48f3c9dca80c',
      'view4bbcd3df12ea42adba2e484c55758c2e',
      'view98125116d6ea4ff1a929ba7c604768bd'
    ].includes(router.currentRoute.value.params.uuid));
  });

  return {
    initializeMetaSystem,
    createViewSettings,
    setViewDefaultSettings,
    removeViewSettings,
    createTagInTagGroup,
    metaRepositoriesLoadingState,
    metaRepositoriesLoaded,
    selectedGlobalFilter,
    globalFilterEnabled,
    $reset
  }
}
