import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { Model } from 'pinia-orm';

declare global {
  interface Window {
    Pusher: Pusher;
    Echo: Echo
  }
}

export const useWebSocket = () => {
  const { data } = useAuth();
  const { tenantUuid } = useProfile();
  const config = useRuntimeConfig();

  const echo = computed(() => {
    if (process.client) {
      if (!window.Echo) {
        // @ts-ignore
        window.Pusher = Pusher;

        console.log('Init Echo')
        window.Echo = new Echo({
          broadcaster: "pusher",
          key: tenantUuid.value,
          wsHost: config.public.PSDBWsUrl,
          wsPort: config.public.PSDBWsPort,
          cluster: "",
          forceTLS: false,
          disableStats: false,
          encrypted: true,
          enabledTransports: ['ws', 'wss'],
          authEndpoint: config.public.basePSDBApiUrl + 'broadcasting/auth',
          auth: {
            headers: {
              'Accept': 'application/json',
              Authorization: 'Bearer ' + data.value.token,
              'X-Tenant': tenantUuid.value,
            }
          },
        });
      }

      return window.Echo;
    }

    return null;
  });

  const registerRepositoryCallbacks = (
    repositoryModel: Model,
    created: Function,
    updated: Function,
    deleted: Function,
  ) => {
    echo.value?.private('repositories.' + repositoryModel.entity)
    .listen('.created', created)
    .listen('.updated', updated)
    .listen('.deleted', deleted);
  }

  const leaveAllChannels = () => {
    echo.value?.leaveAllChannels();
  };

  const $reset = () => {
    if (process.client) {
      window.Echo = null;
    }
  };

  return {
    echo,
    registerRepositoryCallbacks,
    leaveAllChannels,
    $reset
  };
}
