<template>
  <Toast
    position="bottom-right"
    :auto-z-index="false"
    :base-z-index="100000000000"
    style="z-index: 100000000000;"
  />
  <ConfirmDialog />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
const { registerPSFilters } = usePSFilters();
// initialize global toast, confirm
useGlobalToast();
useGlobalConfirm();

onMounted(() => {
  registerPSFilters();
})
</script>

<style>
html {
   font-size: 15px;
}
body {
  margin:0px;
  padding:0px;
  background-color: var(--surface-b);
  font-family: var(--font-family);
  color: var(--text-color);
}

#sentry-feedback {
  --right: 7rem;
  --bottom: 1.5rem;
}
</style>
