import { ViewField } from '../Internal';
import { Mask } from "maska";

export class PhoneViewField extends ViewField {
  static entity = 'phone-view-fields';
  static baseEntity = 'view-fields';

  static fields () {
    return {
      ...super.fields(),
    }
  }

  get propertyPath (): string {
    return this.uuid;
  }

  get dataType (): string {
    return 'text';
  }

  get sortPropertyPath (): string {
    return this.uuid;
  }

  get sortable (): boolean {
    return true;
  }

  get filterPropertyPath (): string {
    return this.uuid + '.number';
  }

  get filterable (): boolean {
    return true;
  }

  get showFilterMatchModes (): boolean {
    return true;
  }

  get filterMatchModeOptions (): Array<Object> | undefined {
    return undefined;
  }

  formatToPlainString (value: Object): string {
    if (value == null) {
      return '';
    }

    const { getPhoneMaskByIso } = usePhoneMasks();
    const mask = new Mask({ mask: getPhoneMaskByIso(value.iso).mask });

    return `${value.code} ${mask.masked(value.number)}`;
  };
}
