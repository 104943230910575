import revive_payload_client_4sVQNw7RlN from "/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/build/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/build/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_AOuQ1DYzjk from "/build/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_M4DzypTK2c from "/build/node_modules/@pinia-orm/nuxt/dist/runtime/plugin.vue3.mjs";
import maska_UHaKf2z1iQ from "/build/plugins/maska.ts";
import sentry_client_shVUlIjFLk from "/build/plugins/sentry.client.ts";
import tawk_messenger_client_T4BIlRqwb5 from "/build/plugins/tawk-messenger.client.ts";
import vue_hotjar_next_client_3KGbCimYhM from "/build/plugins/vue-hotjar-next.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  plugin_AOuQ1DYzjk,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_M4DzypTK2c,
  maska_UHaKf2z1iQ,
  sentry_client_shVUlIjFLk,
  tawk_messenger_client_T4BIlRqwb5,
  vue_hotjar_next_client_3KGbCimYhM
]