import { PSCoreRepositoryUuid } from '@/api/Api';
import * as Sentry from "@sentry/vue";
import { MetaRepository } from '~/models/Models';

export const useProfile = () => {
  const { data } = useAuth();
  const toast = useGlobalToast();
  const route = useRoute();

  const userId = computed(() => {
    const userRepository = useRepo(MetaRepository).find(PSCoreRepositoryUuid.USER);

    //TODO move this to constants fiel47d2f157e46a4e18916351137fdb0dfc
    const loggedUser = userRepository?.useRepo().where('fiel47d2f157e46a4e18916351137fdb0dfc', data.value.attributes.global_uuid).first();

    if (!loggedUser) {
      return null;
    }

    return loggedUser.id;
  });

  const activeTenantUuid = computed(() => {
    return route.params.tenantUuid;
  });

  const tenant = computed(() => {
    if (!data.value) return null;

    return data.value.relationships.tenants.find(t => t.attributes.tenant_uuid === activeTenantUuid.value);
  });

  const tenants = computed(() => {
    if (!data.value) return null;

    return data.value.relationships.tenants;
  });

  const tenantSymbolLink = computed(() => {
    return (tenant.value && tenant.value.attributes.favicon) ? tenant.value.attributes.favicon : '/images/logos/symbol-colored.svg';
  });

  const tenantLogoLink = computed(() => {
    return (tenant.value && tenant.value.attributes.logo) ? tenant.value.attributes.logo : '/images/logos/logo-colored.svg';
  });

  const tenantThemeLink = computed(() => {
    return (tenant.value && tenant.value.attributes.theme) ? tenant.value.attributes.theme : '/themes/pixiu-lara.css';
  });

  const tenantUuid = computed(() => {
    if (!tenant.value) return null;

    const uuid = tenant.value.attributes.tenant_uuid;

    return uuid;
  });

  const tenantName = computed(() => {
    if (!tenant.value)
      return null;

    return tenant.value.attributes.label;
  });

  const changeTenant = () => {
    usePSDBData().$reset();
    usePSDBMeta().$reset();
    useWebSocket().$reset();

    usePSDBMeta().initializeMetaSystem();

    // Sentry Tag
    Sentry.setTag('tenant-uuid', tenantUuid.value);
    Sentry.setUser({
      email: data.value?.attributes?.email,
      name: data.value?.attributes?.full_name
    });

    toast.add({
      severity: 'success',
      summary: 'Přepnuto na ' + tenantName.value,
      life: 3000,
    });
  };

  return {
    userId,
    tenantUuid,
    tenantSymbolLink,
    tenantLogoLink,
    tenantThemeLink,
    tenant,
    tenants,
    tenantName,
    changeTenant
  };
}
