import { View, ViewField, PasswordViewField } from '../Internal';
import { PSViewFieldType } from '@/api/Api';
import { Model } from 'pinia-orm';

export class FormView extends View {
  static entity = 'form-views';
  static baseEntity = 'views';

  static fields () {
    return {
      ...super.fields(),
    }
  }

  get filteredSortedViewFields () {
    return this.sortedViewFields.reduce((carry, viewField) => {
      if (!viewField.isType(PSViewFieldType.HasMany) && !viewField.isType(PSViewFieldType.Computed) && viewField.pivot.show && !viewField.computed) {
        carry.push(viewField);

        // Inject password confirmation
        if (viewField.isType(PSViewFieldType.Password)) {
          const confirmationField = useRepo(PasswordViewField).make(viewField);
          confirmationField.uuid = confirmationField.uuid + '_confirmation';
          confirmationField.label = confirmationField.label + ' potvrzení';
          carry.push(confirmationField);
        }
      }

      return carry;
    }, []);
  }

  getTitle (resource: Model | null) {
    return (resource ? 'Upravit ' : 'Vytvořit ') + this.repository.singular_label_accusative + (resource && this.titleViewField ? ': ' + this.titleViewField.formatToPlainString(this.titleViewField.getValueFromResource(resource)) : '');
  }
}
