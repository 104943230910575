import { Meta, TagGroup } from './Internal';

export class Tag extends Meta {
  static entity = 'tags';

  static fields () {
    return {
      ...super.fields(),
      label: this.string(null),
      color: this.string(null),
      global_id: this.attr(null),
      order_column: this.attr(null),
      tag_group_uuid: this.attr(null),
      tagGroup: this.belongsTo(TagGroup, 'tag_group_uuid'),
    }
  }
}
