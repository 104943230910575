import { Tag, ViewField, TagGroup } from '../Internal';
import { PSFilterMatchMode } from '@/api/Api';

export class SingleSelectViewField extends ViewField {
  static entity = 'single-select-view-fields';
  static baseEntity = 'view-fields';

  static fields () {
    return {
      ...super.fields(),
    }
  }

  get propertyPath (): string {
    return this.uuid;
  }

  get dataType (): string {
    return 'numeric';
  }

  get sortPropertyPath (): string {
    return this.uuid;
  }

  get sortable (): boolean {
    return true;
  }

  get filterPropertyPath (): string {
    return this.uuid;
  }

  get filterable (): boolean {
    return true;
  }

  get showFilterMatchModes (): boolean {
    return true;
  }

  get filterMatchModeOptions (): Array<Object> | undefined {
    return [
      { value: PSFilterMatchMode.EQUALS, label: 'Rovná se' },
      { value: PSFilterMatchMode.NOT_EQUALS, label: 'Nerovná se' },
      { value: PSFilterMatchMode.IN, label: 'Rovná se některému' },
      { value: PSFilterMatchMode.NOT_IN, label: 'Nerovná se žádnému' },
      { value: PSFilterMatchMode.IS_EMPTY, label: 'Je nevyplněný' },
      { value: PSFilterMatchMode.IS_NOT_EMPTY, label: 'Je vyplněný' },
    ];
  }

  formatToPlainString (value: number | null): string {
    if (value == null) return '';

    return this.getTag(value).label;
  }

  get tagGroupUuid (): string {
    return this.meta_data.tag_group_uuid;
  }

  get tagGroup () {
    return useRepo(TagGroup).find(this.tagGroupUuid);
  }

  get inlineCreateEnabled (): boolean {
    return this.meta_data.inline_create_enabled;
  }

  get tags () {
    return useRepo(Tag).where('tag_group_uuid', this.tagGroupUuid).useCache(this.tagGroupUuid).get();
  }

  getTag (value: number) {
    return this.tags.find(tag => tag.global_id == value);
  }
}
