import { ViewField } from '../Internal';

export class RatingViewField extends ViewField {
  static entity = 'rating-view-fields';
  static baseEntity = 'view-fields';

  static fields () {
    return {
      ...super.fields(),
    }
  }

  get propertyPath (): string {
    return this.uuid;
  }

  get dataType (): string {
    return 'numeric';
  }

  get sortPropertyPath (): string {
    return this.uuid;
  }

  get sortable (): boolean {
    return true;
  }

  get filterPropertyPath (): string {
    return this.uuid;
  }

  get filterable (): boolean {
    return true;
  }

  get showFilterMatchModes (): boolean {
    return true;
  }

  get filterMatchModeOptions (): Array<Object> | undefined {
    return undefined;
  }

  get max (): number {
    return this.meta_data.max;
  }

  formatToPlainString (value: string | null): string {
    return '';
  }
}
