import { ViewField, MetaRepository } from '../Internal';

export class HasManyViewField extends ViewField {
  static entity = 'has-many-view-fields';
  static baseEntity = 'view-fields';

  static fields () {
    return {
      ...super.fields(),
    }
  }

  get propertyPath (): string {
    return this.uuid;
  }

  get dataType (): string {
    return '';
  }

  get sortPropertyPath (): string {
    return this.uuid;
  }

  get sortable (): boolean {
    return false;
  }

  get filterPropertyPath (): string {
    return this.uuid;
  }

  get filterable (): boolean {
    return false;
  }

  get showFilterMatchModes (): boolean {
    return false;
  }

  get filterMatchModeOptions (): Array<Object> | undefined {
    return undefined;
  }

  get tableViewUuid (): string {
    return this.meta_data.table_view_uuid;
  }

  get relationFieldUuid (): string {
    return this.meta_data.relation_field_uuid;
  }

  get foreignRepositoryUuid (): string {
    return this.meta_data.foreign_repository_uuid;
  }

  get oppositeRelationUuid (): string {
    return this.meta_data.opposite_relation_uuid;
  }

  get oppositeRelation () {
    return useRepo(ViewField).find(this.oppositeRelationUuid);
  }

  get relatedRepository () {
    return useRepo(MetaRepository).find(this.foreignRepositoryUuid);
  }
}
